/* footer */
footer {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: $full;
    padding-bottom: 22px;
    
    @media screen and (max-width: 1080px) {
        width: $fixH;
        right: 5px;
        z-index: 999;
    }

    @media screen and (max-width: 590px) {
        display: none;
    }

    p {
        padding: 0;
        color: rgba($base-light, .8);
        @include font-size(1.1, 1.6);

        a { 
            color: $dark !important;
            padding-bottom: 2px;
            border-bottom: 2px solid rgba($base-light, .7);
            @include font-size(1, 1.8);
            font-weight: bold;
            display: inline-block;
    
            &:hover {
                @extend .hov;
                border-bottom: 2px solid rgba($base-light, .5);
            }

            &:active {
                @extend .act;
                border-bottom: 2px solid rgba($base-light, .3);
            }
        }
    }
}
@font-face {
    font-family: "icons";
    src: url("icons.eot?e1691f6941de043822be6fd061cae33a?#iefix") format("embedded-opentype"),
url("icons.woff?e1691f6941de043822be6fd061cae33a") format("woff"),
url("icons.ttf?e1691f6941de043822be6fd061cae33a") format("truetype"),
url("icons.svg?e1691f6941de043822be6fd061cae33a#icons") format("svg");
}

.icon-tech {
    line-height: 1;
}

.icon-tech:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.icon-tech.icon-tech-agile:before {
    content: "\f101";
}
.icon-tech.icon-tech-akka:before {
    content: "\f102";
}
.icon-tech.icon-tech-android:before {
    content: "\f103";
}
.icon-tech.icon-tech-angular:before {
    content: "\f104";
}
.icon-tech.icon-tech-ansible:before {
    content: "\f105";
}
.icon-tech.icon-tech-apache:before {
    content: "\f106";
}
.icon-tech.icon-tech-asp:before {
    content: "\f107";
}
.icon-tech.icon-tech-aurelia:before {
    content: "\f108";
}
.icon-tech.icon-tech-aws:before {
    content: "\f109";
}
.icon-tech.icon-tech-backbone:before {
    content: "\f10a";
}
.icon-tech.icon-tech-bash:before {
    content: "\f10b";
}
.icon-tech.icon-tech-bootstrap:before {
    content: "\f10c";
}
.icon-tech.icon-tech-bower:before {
    content: "\f10d";
}
.icon-tech.icon-tech-c:before {
    content: "\f10e";
}
.icon-tech.icon-tech-chef:before {
    content: "\f10f";
}
.icon-tech.icon-tech-clojure:before {
    content: "\f110";
}
.icon-tech.icon-tech-codeigniter:before {
    content: "\f111";
}
.icon-tech.icon-tech-coffeescript:before {
    content: "\f112";
}
.icon-tech.icon-tech-cplusplus:before {
    content: "\f113";
}
.icon-tech.icon-tech-csharp:before {
    content: "\f114";
}
.icon-tech.icon-tech-d3:before {
    content: "\f115";
}
.icon-tech.icon-tech-django:before {
    content: "\f116";
}
.icon-tech.icon-tech-docker:before {
    content: "\f117";
}
.icon-tech.icon-tech-drupal:before {
    content: "\f118";
}
.icon-tech.icon-tech-erlang:before {
    content: "\f119";
}
.icon-tech.icon-tech-expressjs:before {
    content: "\f11a";
}
.icon-tech.icon-tech-foundation:before {
    content: "\f11b";
}
.icon-tech.icon-tech-git:before {
    content: "\f11c";
}
.icon-tech.icon-tech-go:before {
    content: "\f11d";
}
.icon-tech.icon-tech-grunt:before {
    content: "\f11e";
}
.icon-tech.icon-tech-gulp:before {
    content: "\f11f";
}
.icon-tech.icon-tech-hadoop:before {
    content: "\f120";
}
.icon-tech.icon-tech-handlebars:before {
    content: "\f121";
}
.icon-tech.icon-tech-haskell:before {
    content: "\f122";
}
.icon-tech.icon-tech-heroku:before {
    content: "\f123";
}
.icon-tech.icon-tech-illustrator:before {
    content: "\f124";
}
.icon-tech.icon-tech-inkscape:before {
    content: "\f125";
}
.icon-tech.icon-tech-ios:before {
    content: "\f126";
}
.icon-tech.icon-tech-java:before {
    content: "\f127";
}
.icon-tech.icon-tech-javascript:before {
    content: "\f128";
}
.icon-tech.icon-tech-jquery:before {
    content: "\f129";
}
.icon-tech.icon-tech-js:before {
    content: "\f12a";
}
.icon-tech.icon-tech-lavarel:before {
    content: "\f12b";
}
.icon-tech.icon-tech-less:before {
    content: "\f12c";
}
.icon-tech.icon-tech-lisp:before {
    content: "\f12d";
}
.icon-tech.icon-tech-meteor:before {
    content: "\f12e";
}
.icon-tech.icon-tech-mongo:before {
    content: "\f12f";
}
.icon-tech.icon-tech-mustache:before {
    content: "\f130";
}
.icon-tech.icon-tech-mvc:before {
    content: "\f131";
}
.icon-tech.icon-tech-mysql:before {
    content: "\f132";
}
.icon-tech.icon-tech-net:before {
    content: "\f133";
}
.icon-tech.icon-tech-nginx:before {
    content: "\f134";
}
.icon-tech.icon-tech-nodejs:before {
    content: "\f135";
}
.icon-tech.icon-tech-nosql:before {
    content: "\f136";
}
.icon-tech.icon-tech-perl:before {
    content: "\f137";
}
.icon-tech.icon-tech-photoshop:before {
    content: "\f138";
}
.icon-tech.icon-tech-php:before {
    content: "\f139";
}
.icon-tech.icon-tech-postgres:before {
    content: "\f13a";
}
.icon-tech.icon-tech-powershell:before {
    content: "\f13b";
}
.icon-tech.icon-tech-puppet:before {
    content: "\f13c";
}
.icon-tech.icon-tech-python:before {
    content: "\f13d";
}
.icon-tech.icon-tech-r:before {
    content: "\f13e";
}
.icon-tech.icon-tech-rails:before {
    content: "\f13f";
}
.icon-tech.icon-tech-react:before {
    content: "\f140";
}
.icon-tech.icon-tech-redis:before {
    content: "\f141";
}
.icon-tech.icon-tech-ruby:before {
    content: "\f142";
}
.icon-tech.icon-tech-rust:before {
    content: "\f143";
}
.icon-tech.icon-tech-sass:before {
    content: "\f144";
}
.icon-tech.icon-tech-scala:before {
    content: "\f145";
}
.icon-tech.icon-tech-socketio:before {
    content: "\f146";
}
.icon-tech.icon-tech-spark:before {
    content: "\f147";
}
.icon-tech.icon-tech-spring:before {
    content: "\f148";
}
.icon-tech.icon-tech-sql-server:before {
    content: "\f149";
}
.icon-tech.icon-tech-sql:before {
    content: "\f14a";
}
.icon-tech.icon-tech-stylus:before {
    content: "\f14b";
}
.icon-tech.icon-tech-svg:before {
    content: "\f14c";
}
.icon-tech.icon-tech-swift:before {
    content: "\f14d";
}
.icon-tech.icon-tech-symphony:before {
    content: "\f14e";
}
.icon-tech.icon-tech-travis:before {
    content: "\f14f";
}
.icon-tech.icon-tech-typescript:before {
    content: "\f150";
}
.icon-tech.icon-tech-unity:before {
    content: "\f151";
}
.icon-tech.icon-tech-unix:before {
    content: "\f152";
}
.icon-tech.icon-tech-vue:before {
    content: "\f153";
}
.icon-tech.icon-tech-websockets:before {
    content: "\f154";
}
.icon-tech.icon-tech-windows:before {
    content: "\f155";
}
.icon-tech.icon-tech-yii:before {
    content: "\f156";
}
.icon-tech.icon-tech-zend:before {
    content: "\f157";
}

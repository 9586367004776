/******************************************************************
Project: MZE.ninja / code by: Milos Zekovic
Date: Summer 2018
******************************************************************/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,700&subset=latin-ext");
@import url("assets/fontawesome/web-fonts-with-css/css/fontawesome-all.min.css");
@import url("assets/font-mfizz-2.4.1/font-mfizz.css");
@import url("assets/tech-icons/icons.css");
body,
div,
span,
section,
article,
header,
footer,
nav,
aside,
hgroup,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
font,
img,
strong,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
select,
option,
input,
textarea,
table,
tr,
td,
th,
blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0; }

a:click {
  outline: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

ul,
ol,
li {
  list-style: none; }

.right-text {
  text-align: right; }

.right {
  float: right; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

input, form, textarea {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit; }

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

body {
  margin: 0;
  line-height: normal; }

/* @media screen and (max-width: 1279px) {
    body, html { 
        overflow: auto; 
        overflow-y: auto !important; 
        overflow-x: hidden !important;
    }
} */
a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

a,
p a,
a img {
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"], input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled],
html input[disabled] {
  cursor: default; }

button {
  background: transparent;
  border: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input {
  /* -webkit-appearance: none; */
  border-radius: 0;
  line-height: 2em;
  transition: opacity .4s ease-out;
  outline: none; }

.error input {
  border-color: #FF4C4C; }

.error input[type="submit"] {
  background: #FF4C4C;
  color: #fff; }

.loading input {
  opacity: .5; }

a,
p a,
a img {
  transition: all .1s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease; }

.no-fade, .no-fade * {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none; }

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.centering, aside article svg, aside nav {
  display: block;
  margin: 0 auto;
  float: none; }

.clear {
  display: block;
  clear: both; }

.button, input[type="submit"] {
  cursor: pointer; }

.button.desabled {
  cursor: default; }

/* sass variables */
/* sass mixing */
/* extendions */
.floating, .home--wrapper .tech--icon--list li, .about--wrapper article, .about--wrapper article.content ul li span,
.about--wrapper article.about--site--wraper ul li span, .contact--wrapper #contact ul li:nth-child(1), .contact--wrapper #contact ul li:nth-child(2) {
  display: inline-block;
  vertical-align: top; }

.hov, a:hover, button:hover, body[data-theme="light"] a:hover, footer p a:hover, .switch .switch__input:hover {
  opacity: .80;
  -moz-opacity: .80;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  transition: all 0.4s ease; }

.act, a:active, button:active, footer p a:active {
  opacity: .60;
  -moz-opacity: .60;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  transition: all 0.4s ease; }

.fade, aside article a, aside article a:hover, aside article a:active, aside article a *, aside article a:hover *, aside article a:active *, aside nav ul li a, aside nav ul li a:hover, aside nav ul li a:active, aside nav ul li a *, aside nav ul li a:hover *, aside nav ul li a:active * {
  transition: all 0.4s ease; }

.no--fade,
.no--fade * {
  transition: none; }

.fade--logos, .home--wrapper .tech--icon--list li, .contact--wrapper #contact ul li input, .contact--wrapper #contact ul li textarea {
  transition: all 1s ease-in-out 0s; }

.opacity--reset, aside article a, aside article a:hover, aside article a:active, aside article a *, aside article a:hover *, aside article a:active *, aside nav ul li a, aside nav ul li a:hover, aside nav ul li a:active, aside nav ul li a *, aside nav ul li a:hover *, aside nav ul li a:active *, body[data-theme="light"] aside article a:hover, body[data-theme="light"] aside nav ul li a:hover {
  opacity: 1.0;
  -moz-opacity: 1.0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

.opacity--grad, .fancy:after {
  opacity: .2;
  -moz-opacity: .2;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"; }

.scal {
  -webkit-transform: scale(1.05);
  transform: scale(1.05); }

.scal--no {
  -webkit-transform: none;
  transform: none; }

.centering, aside article svg, aside nav {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.centering--left, aside nav {
  left: 0;
  margin-right: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); }

.img, .about--wrapper .img--wrapp img {
  width: 100%;
  height: auto;
  line-height: 0; }

/* fonts */
.gradient, h1 strong, .fancy, .fancy:after {
  background: linear-gradient(to right, #00b4b1 30%, #f7df93 80%); }

.gradient--title, h1 strong {
  background: linear-gradient(to right, #00b4b1 30%, #a1fcdf 80%); }

.text--gradient, h1 strong, .fancy {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

/* animations */
@keyframes content-blur {
  0% {
    -webkit-filter: blur(3px); }
  100% {
    -webkit-filter: blur(0px); } }

@-webkit-keyframes content-blur {
  0% {
    -webkit-filter: blur(3px); }
  100% {
    -webkit-filter: blur(0px); } }

/* global */
html,
body,
div#root,
div#main--app--screen,
div#main--screen {
  height: 100%;
  min-height: 100%; }

html {
  font-size: 62.5%; }

body {
  background: #1c1d25;
  color: #f9f9f9;
  font-size: 18px;
  line-height: 34px;
  font-size: 1.8rem;
  line-height: 3.4rem; }
  @media screen and (max-width: 1080px) {
    body {
      font-size: 15px;
      line-height: 28px;
      font-size: 1.5rem;
      line-height: 2.8rem; } }

body,
body *,
input,
textarea,
select {
  font-family: 'Poppins', Arial, sans-serif; }

a {
  color: #a1fcdf;
  text-decoration: none; }
  a:hover {
    color: #f9f9f9; }
  a:focus {
    outline: 0;
    border: 0; }

h1, h2, h3, h4 {
  font-weight: 300; }

h1 {
  font-weight: 700;
  font-size: 75px;
  line-height: 80px;
  font-size: 7.5rem;
  line-height: 8rem;
  margin-bottom: 40px;
  text-transform: uppercase; }
  h1 strong i {
    font-style: normal; }
    @media screen and (max-width: 470px) {
      h1 strong i {
        display: none; } }
  @media screen and (max-width: 1880px) {
    h1 {
      font-size: 65px;
      line-height: 70px;
      font-size: 6.5rem;
      line-height: 7rem; } }
  @media screen and (max-width: 1080px) {
    h1 {
      font-size: 50px;
      line-height: 55px;
      font-size: 5rem;
      line-height: 5.5rem;
      margin-bottom: 35px; } }
  @media screen and (max-width: 580px) {
    h1 {
      font-size: 38px;
      line-height: 45px;
      font-size: 3.8rem;
      line-height: 4.5rem;
      margin-bottom: 30px; } }

h2 {
  font-size: 30px;
  line-height: 35px;
  font-size: 3rem;
  line-height: 3.5rem; }
  @media screen and (max-width: 1080px) {
    h2 {
      font-size: 25px;
      line-height: 30px;
      font-size: 2.5rem;
      line-height: 3rem; } }

h3,
h4 {
  font-weight: 700;
  padding: 20px 0; }

h4 {
  padding-bottom: 5px; }

p {
  padding-bottom: 20px; }
  p.big {
    font-size: 22px;
    line-height: 48px;
    font-size: 2.2rem;
    line-height: 4.8rem; }
    @media screen and (max-width: 1080px) {
      p.big {
        font-size: 18px;
        line-height: 40px;
        font-size: 1.8rem;
        line-height: 4rem; } }

.fancy {
  padding-bottom: 3px;
  position: relative;
  font-weight: bold; }
  .fancy:after {
    content: "";
    position: absolute;
    z-index: 9999;
    bottom: -3px;
    left: 0;
    height: 3px;
    width: 100%; }

button {
  padding: 0;
  font-size: 22px;
  line-height: 32px;
  font-size: 2.2rem;
  line-height: 3.2rem; }
  button:focus {
    outline: 0;
    border: 0; }

/* custom scrollbar */
.scrollarea.area {
  height: 100% !important;
  min-height: 100% !important; }

.scrollarea .scrollbar-container:hover {
  background: #00b4b1 !important; }

/* base grid */
section {
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 111; }
  section section {
    height: 100%;
    min-height: 100%;
    width: 100%; }
  section .container {
    margin: 0 auto;
    width: 70%;
    padding: 6% 0 150px 80px; }
    @media screen and (max-width: 1080px) {
      section .container {
        width: 74%;
        padding-left: 0; } }
    @media screen and (max-width: 580px) {
      section .container {
        padding-bottom: 110px;
        padding-top: 11%; } }

/* Light Theme */
body[data-theme="light"] {
  background: #f9f9f9;
  color: #1c1d25; }
  body[data-theme="light"] a {
    color: #00b4b1;
    text-decoration: none; }
    body[data-theme="light"] a:hover {
      color: #00b4b1; }

/* NavBar */
aside {
  background: #00b4b1;
  height: 100%;
  min-height: 100%;
  width: 80px;
  position: fixed;
  z-index: 888;
  -webkit-animation: content-blur 0.5s;
  animation: content-blur 0.5s; }
  @media screen and (max-width: 1080px) {
    aside {
      width: 100%;
      height: 80px;
      min-height: 80px;
      left: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 10px 5px #101013; } }
  @media screen and (max-width: 480px) {
    aside {
      height: 60px;
      min-height: 60px; } }
  aside article {
    position: relative;
    background: #1c1d25; }
    @media screen and (max-width: 1080px) {
      aside article {
        width: 80px;
        height: 80px;
        z-index: 889;
        background: #00b4b1; } }
    @media screen and (max-width: 480px) {
      aside article {
        width: 60px;
        height: 60px; } }
    aside article svg {
      fill: #00b4b1;
      width: 30px; }
      @media screen and (max-width: 1080px) {
        aside article svg {
          fill: #a1fcdf; } }
      @media screen and (max-width: 480px) {
        aside article svg {
          width: 20px; } }
    aside article a {
      display: block;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      transition-property: color;
      transition-duration: .2s;
      width: 80px;
      height: 80px; }
      @media screen and (max-width: 480px) {
        aside article a {
          width: 60px;
          height: 60px; } }
      aside article a:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #00b4b1;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-duration: .5s;
        transition-timing-function: ease-out; }
        @media screen and (max-width: 1080px) {
          aside article a:before {
            -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
            background: #1c1d25; } }
      aside article a:hover:before, aside article a:active:before {
        -webkit-transform: scaleY(1);
        transform: scaleY(1); }
      aside article a:hover svg {
        fill: #a1fcdf; }
        @media screen and (max-width: 1080px) {
          aside article a:hover svg {
            fill: #00b4b1; } }
  aside nav {
    width: 100%;
    margin-top: 40px; }
    @media screen and (max-width: 1080px) {
      aside nav {
        margin-top: 5px;
        -webkit-transform: translate(none, none);
        transform: translate(none, none);
        text-align: center;
        z-index: 888; } }
    @media screen and (max-width: 590px) {
      aside nav {
        text-align: right; } }
    @media screen and (max-width: 1080px) {
      aside nav ul li {
        display: inline-block;
        width: 80px;
        height: 80px; } }
    @media screen and (max-width: 480px) {
      aside nav ul li {
        width: 60px;
        height: 60px; } }
    aside nav ul li a {
      display: block;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      transition-property: color;
      transition-duration: .2s; }
      aside nav ul li a:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #f7df93;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-duration: .2s;
        transition-timing-function: ease-out; }
        @media screen and (max-width: 1080px) {
          aside nav ul li a:before {
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%; } }
      aside nav ul li a:hover:before, aside nav ul li a:focus:before, aside nav ul li a:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1); }
    aside nav ul li div {
      width: 100%;
      position: relative;
      padding: 15px 0;
      text-align: center;
      background: rgba(161, 252, 223, 0.2); }
      aside nav ul li div img {
        width: 60%;
        display: block;
        margin: 0 auto;
        color: #00b4b1;
        text-indent: -9999px; }
      @media screen and (max-width: 1080px) {
        aside nav ul li div {
          width: 80px;
          height: 80px;
          padding: 0; } }
      @media screen and (max-width: 480px) {
        aside nav ul li div {
          width: 60px;
          height: 60px; } }
    aside nav ul li:nth-child(1) div img {
      width: 35%; }
      @media screen and (max-width: 1080px) {
        aside nav ul li:nth-child(1) div img {
          padding-top: 17px; } }
      @media screen and (max-width: 480px) {
        aside nav ul li:nth-child(1) div img {
          padding-top: 13px; } }
    aside nav ul li:nth-child(2) div {
      background: rgba(161, 252, 223, 0.4); }
      aside nav ul li:nth-child(2) div img {
        width: 55%; }
        @media screen and (max-width: 1080px) {
          aside nav ul li:nth-child(2) div img {
            padding-top: 15px; } }
        @media screen and (max-width: 480px) {
          aside nav ul li:nth-child(2) div img {
            padding-top: 12px; } }
    aside nav ul li:nth-child(3) div {
      background: rgba(161, 252, 223, 0.6); }
      @media screen and (max-width: 1080px) {
        aside nav ul li:nth-child(3) div img {
          padding-top: 18px; } }
      @media screen and (max-width: 480px) {
        aside nav ul li:nth-child(3) div img {
          padding-top: 14px; } }
    aside nav ul li:nth-child(4) div img {
      width: 40%; }
      @media screen and (max-width: 1080px) {
        aside nav ul li:nth-child(4) div img {
          padding-top: 21px; } }
      @media screen and (max-width: 480px) {
        aside nav ul li:nth-child(4) div img {
          padding-top: 16px; } }
    aside nav ul li .nav--button-active,
    aside nav ul li .nav--button-light-active {
      display: none; }
    aside nav ul li .active div {
      background: #1c1d25; }
    aside nav ul li .active .nav--button-active {
      display: block; }
      @media screen and (max-width: 1080px) {
        aside nav ul li .active .nav--button-active {
          margin-top: -1px;
          height: 81px; } }
      @media screen and (max-width: 480px) {
        aside nav ul li .active .nav--button-active {
          height: 61px; } }
    aside nav ul li .active .nav--button {
      display: none; }
    aside nav ul li:nth-child(1) .active div, aside nav ul li:nth-child(4) .active div {
      background: #101013; }
    @media screen and (max-width: 1080px) {
      aside nav ul li:nth-child(3) .active div {
        background: #101013; } }

/* Light Theme */
@media screen and (max-width: 1080px) {
  body[data-theme="light"] aside {
    box-shadow: 0 0 4px 1px rgba(16, 16, 19, 0.2); } }

body[data-theme="light"] aside article {
  background: #f9f9f9; }
  @media screen and (max-width: 1080px) {
    body[data-theme="light"] aside article {
      background: #00b4b1; } }
  @media screen and (max-width: 1080px) {
    body[data-theme="light"] aside article a:before {
      background: #f9f9f9; } }

body[data-theme="light"] aside nav ul li .active .nav--button-active {
  display: none; }

body[data-theme="light"] aside nav ul li .active .nav--button-light-active {
  display: block; }

body[data-theme="light"] aside nav ul li:nth-child(1) .active div,
body[data-theme="light"] aside nav ul li:nth-child(4) .active div {
  background: #eee; }

body[data-theme="light"] aside nav ul li:nth-child(2) .active div,
body[data-theme="light"] aside nav ul li:nth-child(3) .active div {
  background: #f9f9f9; }

/* Homepage */
.home--wrapper {
  background: linear-gradient(215deg, #1c1d25 70%, #101013 30%);
  -webkit-animation: content-blur 0.5s;
  animation: content-blur 0.5s; }
  .home--wrapper section {
    background: linear-gradient(130deg, transparent 95%, #f7df93 5%); }
    @media screen and (max-width: 1080px) {
      .home--wrapper section {
        background: linear-gradient(20deg, transparent 96%, #00b4b1 10%); } }
  .home--wrapper h2, .home--wrapper p {
    padding-left: 13px;
    padding-right: 13px; }
    @media screen and (max-width: 1594px) {
      .home--wrapper h2, .home--wrapper p {
        padding-left: 0;
        padding-right: 0; } }
  .home--wrapper h2 {
    padding-bottom: 25px;
    text-transform: none; }
    .home--wrapper h2 i {
      font-style: normal;
      -webkit-animation: content-blur 0.35s;
      animation: content-blur 0.35s; }
  .home--wrapper p {
    text-align: justify; }
    @media screen and (max-width: 700px) {
      .home--wrapper p {
        text-align: left; } }
  .home--wrapper .tech--icon--list {
    margin-top: 60px;
    padding-right: 13px;
    padding-bottom: 150px;
    float: right; }
    @media screen and (max-width: 1594px) {
      .home--wrapper .tech--icon--list {
        margin-top: 30px;
        padding-right: 0; } }
    @media screen and (max-width: 930px) {
      .home--wrapper .tech--icon--list {
        margin-top: 20px; } }
    @media screen and (max-width: 700px) {
      .home--wrapper .tech--icon--list {
        padding-bottom: 0;
        float: inherit;
        text-align: left; } }
    .home--wrapper .tech--icon--list li {
      padding-left: 20px;
      font-size: 30px;
      line-height: 30px;
      font-size: 3rem;
      line-height: 3rem;
      color: rgba(16, 16, 19, 0.25); }
      @media screen and (max-width: 930px) {
        .home--wrapper .tech--icon--list li {
          font-size: 20px;
          line-height: 20px;
          font-size: 2rem;
          line-height: 2rem; } }
      @media screen and (max-width: 700px) {
        .home--wrapper .tech--icon--list li {
          padding: 0 20px 20px 0 !important; } }
      .home--wrapper .tech--icon--list li:nth-child(4) {
        font-size: 38px;
        line-height: 30px;
        font-size: 3.8rem;
        line-height: 3rem;
        margin-top: -4px;
        padding-left: 25px; }
        @media screen and (max-width: 930px) {
          .home--wrapper .tech--icon--list li:nth-child(4) {
            margin-top: -2px;
            font-size: 25px;
            line-height: 20px;
            font-size: 2.5rem;
            line-height: 2rem; } }
      .home--wrapper .tech--icon--list li:nth-child(10) {
        font-size: 30px;
        line-height: 20px;
        font-size: 3rem;
        line-height: 2rem;
        padding-left: 20px; }
        @media screen and (max-width: 930px) {
          .home--wrapper .tech--icon--list li:nth-child(10) {
            font-size: 20px;
            line-height: 20px;
            font-size: 2rem;
            line-height: 2rem; } }
      .home--wrapper .tech--icon--list li:nth-child(11), .home--wrapper .tech--icon--list li:nth-child(12) {
        font-size: 35px;
        line-height: 30px;
        font-size: 3.5rem;
        line-height: 3rem;
        margin-top: -4px; }
        @media screen and (max-width: 930px) {
          .home--wrapper .tech--icon--list li:nth-child(11), .home--wrapper .tech--icon--list li:nth-child(12) {
            font-size: 25px;
            line-height: 20px;
            font-size: 2.5rem;
            line-height: 2rem; } }
      .home--wrapper .tech--icon--list li:nth-child(13) {
        font-size: 30px;
        line-height: 20px;
        font-size: 3rem;
        line-height: 2rem; }
        @media screen and (max-width: 930px) {
          .home--wrapper .tech--icon--list li:nth-child(13) {
            font-size: 20px;
            line-height: 20px;
            font-size: 2rem;
            line-height: 2rem; } }
      .home--wrapper .tech--icon--list li:nth-child(5), .home--wrapper .tech--icon--list li:nth-child(11) {
        padding-left: 15px; }
      .home--wrapper .tech--icon--list li:nth-child(12) {
        padding-left: 10px; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(1) {
    color: #e34f26;
    transition: all 1s ease-in-out 0s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(2) {
    color: #007dc6;
    transition: all 1s ease-in-out 0.2s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(3) {
    color: #563e7c;
    transition: all 1s ease-in-out 0.4s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(4) {
    color: #f0db4f;
    transition: all 1s ease-in-out 0.6s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(5) {
    color: #0b68ad;
    transition: all 1s ease-in-out 0.8s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(6) {
    color: #ffb13b;
    transition: all 1s ease-in-out 1s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(7) {
    color: #0ad7ff;
    transition: all 1s ease-in-out 1.2s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(8) {
    color: #41b883;
    transition: all 1s ease-in-out 1.4s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(9) {
    color: #1e8cbe;
    transition: all 1s ease-in-out 1.6s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(10) {
    color: #cd6799;
    transition: all 1s ease-in-out 1.8s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(11) {
    color: #e84c50;
    transition: all 1s ease-in-out 2s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(12) {
    color: #68b445;
    transition: all 1s ease-in-out 2.2s; }
  .home--wrapper .container:hover .tech--icon--list li:nth-child(13) {
    color: #408bfa;
    transition: all 1s ease-in-out 2.4s; }

/* Light Theme */
body[data-theme="light"] .home--wrapper {
  background: linear-gradient(215deg, #f9f9f9 70%, #eee 30%); }
  body[data-theme="light"] .home--wrapper .tech--icon--list li {
    color: rgba(16, 16, 19, 0.03); }

/* About */
.about--wrapper {
  background: linear-gradient(115deg, #1c1d25 75%, #101013 25%);
  -webkit-animation: content-blur 0.5s;
  animation: content-blur 0.5s; }
  .about--wrapper section {
    background: linear-gradient(105deg, transparent 95%, #00b4b1 5%); }
    @media screen and (max-width: 1080px) {
      .about--wrapper section {
        background: linear-gradient(15deg, transparent 95%, #101013 5%); } }
  .about--wrapper .icon--wrapp {
    width: 50%;
    text-align: center;
    margin: 25px 0 10px; }
    @media screen and (max-width: 1380px) {
      .about--wrapper .icon--wrapp {
        width: 60%; } }
    @media screen and (max-width: 1200px) {
      .about--wrapper .icon--wrapp {
        width: 70%; } }
    @media screen and (max-width: 750px) {
      .about--wrapper .icon--wrapp {
        width: 180px; } }
    .about--wrapper .icon--wrapp i {
      font-size: 35px;
      line-height: 35px;
      font-size: 3.5rem;
      line-height: 3.5rem;
      margin: 0 10px; }
    .about--wrapper .icon--wrapp a:nth-child(1) {
      color: #2eadef; }
      .about--wrapper .icon--wrapp a:nth-child(1):hover {
        color: #0077b5; }
    .about--wrapper .icon--wrapp a:nth-child(2) {
      color: #ffc100; }
      .about--wrapper .icon--wrapp a:nth-child(2):hover {
        color: #fbcc3a; }
  .about--wrapper .img--wrapp {
    width: 50%;
    line-height: 0; }
    @media screen and (max-width: 1380px) {
      .about--wrapper .img--wrapp {
        width: 60%; } }
    @media screen and (max-width: 1200px) {
      .about--wrapper .img--wrapp {
        width: 70%; } }
    @media screen and (max-width: 750px) {
      .about--wrapper .img--wrapp {
        width: 180px; } }
    .about--wrapper .img--wrapp img {
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      color: #1c1d25;
      box-shadow: 0 6px 5px -5px #00b4b1; }
  @media screen and (max-width: 750px) {
    .about--wrapper article {
      display: block;
      width: 100% !important;
      padding: 0 !important; } }
  .about--wrapper article.content,
  .about--wrapper article.about--site--wraper {
    width: 66.66667%;
    padding-right: 50px;
    text-align: justify; }
    @media screen and (max-width: 1380px) {
      .about--wrapper article.content,
      .about--wrapper article.about--site--wraper {
        padding-right: 30px; } }
    @media screen and (max-width: 1200px) {
      .about--wrapper article.content,
      .about--wrapper article.about--site--wraper {
        padding-right: 25px; } }
    @media screen and (max-width: 700px) {
      .about--wrapper article.content,
      .about--wrapper article.about--site--wraper {
        text-align: left; } }
    .about--wrapper article.content .icon--wrapp,
    .about--wrapper article.content .img--wrapp,
    .about--wrapper article.about--site--wraper .icon--wrapp,
    .about--wrapper article.about--site--wraper .img--wrapp {
      display: none; }
      @media screen and (max-width: 750px) {
        .about--wrapper article.content .icon--wrapp,
        .about--wrapper article.content .img--wrapp,
        .about--wrapper article.about--site--wraper .icon--wrapp,
        .about--wrapper article.about--site--wraper .img--wrapp {
          display: block; } }
    .about--wrapper article.content .icon--wrapp,
    .about--wrapper article.about--site--wraper .icon--wrapp {
      margin-bottom: 20px; }
    .about--wrapper article.content .img--wrapp,
    .about--wrapper article.about--site--wraper .img--wrapp {
      margin-top: 20px; }
    .about--wrapper article.content ul,
    .about--wrapper article.about--site--wraper ul {
      padding: 0 0 30px 20px;
      margin-top: -5px; }
      .about--wrapper article.content ul li,
      .about--wrapper article.about--site--wraper ul li {
        padding-bottom: 10px;
        text-align: left;
        font-size: 16px;
        line-height: 30px;
        font-size: 1.6rem;
        line-height: 3rem; }
        @media screen and (max-width: 1080px) {
          .about--wrapper article.content ul li,
          .about--wrapper article.about--site--wraper ul li {
            font-size: 14px;
            line-height: 28px;
            font-size: 1.4rem;
            line-height: 2.8rem; } }
        .about--wrapper article.content ul li::before,
        .about--wrapper article.about--site--wraper ul li::before {
          content: '\2022';
          color: #f7df93;
          padding-right: 20px; }
        .about--wrapper article.content ul li span,
        .about--wrapper article.about--site--wraper ul li span {
          width: 90%; }
          @media screen and (max-width: 769px) {
            .about--wrapper article.content ul li span,
            .about--wrapper article.about--site--wraper ul li span {
              width: 75%; } }
  .about--wrapper article.sidebar {
    padding-left: 50px;
    width: 33.33333%;
    margin-top: -120px; }
    @media screen and (max-width: 1380px) {
      .about--wrapper article.sidebar {
        padding-left: 30px;
        margin-top: -100px; } }
    @media screen and (max-width: 1200px) {
      .about--wrapper article.sidebar {
        padding-left: 25px; } }
    @media screen and (max-width: 1080px) {
      .about--wrapper article.sidebar {
        margin-top: -80px; } }
    @media screen and (max-width: 750px) {
      .about--wrapper article.sidebar {
        margin-top: 0; }
        .about--wrapper article.sidebar .icon--wrapp,
        .about--wrapper article.sidebar .img--wrapp {
          display: none; } }
    .about--wrapper article.sidebar h4 {
      color: rgba(249, 249, 249, 0.7); }
    .about--wrapper article.sidebar p {
      color: rgba(249, 249, 249, 0.6);
      font-size: 14px;
      line-height: 34px;
      font-size: 1.4rem;
      line-height: 3.4rem; }
  .about--wrapper article.about--site--wraper {
    margin-top: 50px; }
    @media screen and (max-width: 1080px) {
      .about--wrapper article.about--site--wraper {
        margin-top: 35px;
        display: block;
        width: 100% !important;
        padding: 0 !important; } }
    .about--wrapper article.about--site--wraper .about--site {
      background: #a1fcdf;
      color: #1c1d25;
      padding: 5px 30px; }
    .about--wrapper article.about--site--wraper p {
      font-size: 14px;
      line-height: 26px;
      font-size: 1.4rem;
      line-height: 2.6rem; }

/* Light Theme */
body[data-theme="light"] .about--wrapper {
  background: linear-gradient(115deg, #f9f9f9 75%, #eee 25%); }
  body[data-theme="light"] .about--wrapper .img--wrapp img {
    color: #f9f9f9;
    box-shadow: 0 6px 5px -5px #1c1d25; }
  body[data-theme="light"] .about--wrapper article.content ul li::before {
    color: #00b4b1; }
  body[data-theme="light"] .about--wrapper article.sidebar h4 {
    color: rgba(28, 29, 37, 0.8); }
  body[data-theme="light"] .about--wrapper article.sidebar p {
    color: rgba(28, 29, 37, 0.7); }

/* Portfolio */
.portfolio--wrapper {
  background: linear-gradient(-197deg, #1c1d25 65%, #101013 35%);
  -webkit-animation: content-blur 0.5s;
  animation: content-blur 0.5s; }
  .portfolio--wrapper section {
    background: linear-gradient(20deg, transparent 92%, #00b4b1 8%); }
    @media screen and (max-width: 1080px) {
      .portfolio--wrapper section {
        background: linear-gradient(70deg, transparent 92%, #f7df93 8%); } }
  .portfolio--wrapper .container ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "portfolio-1 portfolio-1 portfolio-2"  "portfolio-1 portfolio-1 portfolio-3"  "portfolio-4 portfolio-5 portfolio-6"  "portfolio-7 portfolio-8 portfolio-9"  "portfolio-10 portfolio-11 portfolio-11"  "portfolio-12 portfolio-11 portfolio-11"  "portfolio-13 portfolio-14 portfolio-15";
    grid-gap: 10px 10px; }
    @media screen and (max-width: 1440px) {
      .portfolio--wrapper .container ul {
        display: block; } }
    .portfolio--wrapper .container ul li {
      line-height: 0; }
      @media screen and (max-width: 1440px) {
        .portfolio--wrapper .container ul li {
          width: 50%;
          padding: 0 10px 10px 0;
          display: inline-block; } }
      @media screen and (max-width: 900px) {
        .portfolio--wrapper .container ul li {
          width: 100%;
          display: block;
          padding-right: 0;
          padding-bottom: 30px; } }
      .portfolio--wrapper .container ul li:nth-child(1) {
        grid-area: portfolio-1; }
      .portfolio--wrapper .container ul li:nth-child(2) {
        grid-area: portfolio-2; }
      .portfolio--wrapper .container ul li:nth-child(3) {
        grid-area: portfolio-3; }
      .portfolio--wrapper .container ul li:nth-child(4) {
        grid-area: portfolio-4; }
      .portfolio--wrapper .container ul li:nth-child(5) {
        grid-area: portfolio-5; }
      .portfolio--wrapper .container ul li:nth-child(6) {
        grid-area: portfolio-6; }
      .portfolio--wrapper .container ul li:nth-child(7) {
        grid-area: portfolio-7; }
      .portfolio--wrapper .container ul li:nth-child(8) {
        grid-area: portfolio-8; }
      .portfolio--wrapper .container ul li:nth-child(9) {
        grid-area: portfolio-9; }
      .portfolio--wrapper .container ul li:nth-child(10) {
        grid-area: portfolio-10; }
      .portfolio--wrapper .container ul li:nth-child(11) {
        grid-area: portfolio-11; }
      .portfolio--wrapper .container ul li:nth-child(12) {
        grid-area: portfolio-12; }
      .portfolio--wrapper .container ul li:nth-child(13) {
        grid-area: portfolio-13; }
      .portfolio--wrapper .container ul li:nth-child(14) {
        grid-area: portfolio-14; }
      .portfolio--wrapper .container ul li:nth-child(15) {
        grid-area: portfolio-15; }
      .portfolio--wrapper .container ul li article {
        overflow: hidden;
        position: relative;
        height: 100%; }
        .portfolio--wrapper .container ul li article span {
          width: 100%;
          height: 100%; }
      .portfolio--wrapper .container ul li img {
        position: relative;
        z-index: 44;
        color: #1c1d25;
        width: 100%;
        height: 100%; }
      .portfolio--wrapper .container ul li div {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 55;
        background: rgba(28, 29, 37, 0.15); }
        @media screen and (min-width: 1081px) {
          .portfolio--wrapper .container ul li div {
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            transition-property: color;
            transition-duration: .2s; } }
        .portfolio--wrapper .container ul li div span {
          position: absolute;
          z-index: -1;
          background: rgba(28, 29, 37, 0.9);
          padding: 25px 30px;
          width: auto;
          height: auto;
          font-size: 15px;
          line-height: 34px;
          font-size: 1.5rem;
          line-height: 3.4rem; }
          @media screen and (min-width: 1081px) {
            .portfolio--wrapper .container ul li div span {
              top: -1px;
              left: -1px;
              right: -1px;
              bottom: -1px;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
              transition-property: -webkit-transform;
              transition-property: transform;
              transition-property: transform, -webkit-transform;
              transition-duration: .3s;
              transition-timing-function: ease-out; } }
          @media screen and (max-width: 1080px) {
            .portfolio--wrapper .container ul li div span {
              background: rgba(28, 29, 37, 0.75);
              width: 100%;
              height: 100%; } }
          @media screen and (max-width: 480px) {
            .portfolio--wrapper .container ul li div span {
              padding: 15px 20px; } }
          .portfolio--wrapper .container ul li div span h2 {
            color: #00b4b1;
            font-size: 24px;
            line-height: 34px;
            font-size: 2.4rem;
            line-height: 3.4rem; }
            @media screen and (max-width: 480px) {
              .portfolio--wrapper .container ul li div span h2 {
                font-size: 22px;
                line-height: 28px;
                font-size: 2.2rem;
                line-height: 2.8rem; } }
          .portfolio--wrapper .container ul li div span p, .portfolio--wrapper .container ul li div span h3 {
            padding: 0; }
          .portfolio--wrapper .container ul li div span h3 {
            font-size: 14px;
            line-height: 34px;
            font-size: 1.4rem;
            line-height: 3.4rem; }
            @media screen and (max-width: 480px) {
              .portfolio--wrapper .container ul li div span h3 {
                font-size: 12px;
                line-height: 22px;
                font-size: 1.2rem;
                line-height: 2.2rem;
                padding-top: 5px; } }
          .portfolio--wrapper .container ul li div span i {
            padding: 0 5px; }
          .portfolio--wrapper .container ul li div span p {
            position: absolute;
            bottom: 17px; }
            @media screen and (max-width: 480px) {
              .portfolio--wrapper .container ul li div span p {
                bottom: 12px;
                font-size: 13px;
                line-height: 30px;
                font-size: 1.3rem;
                line-height: 3rem; } }
            @media screen and (max-width: 370px) {
              .portfolio--wrapper .container ul li div span p {
                font-size: 12px;
                line-height: 30px;
                font-size: 1.2rem;
                line-height: 3rem; } }
        @media screen and (min-width: 1081px) {
          .portfolio--wrapper .container ul li div:hover span,
          .portfolio--wrapper .container ul li div:focus span,
          .portfolio--wrapper .container ul li div:active span {
            -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

/* Light Theme */
body[data-theme="light"] .portfolio--wrapper {
  background: linear-gradient(-197deg, #f9f9f9 65%, #eee 35%); }
  body[data-theme="light"] .portfolio--wrapper .container ul li div {
    background: rgba(249, 249, 249, 0.2); }
    body[data-theme="light"] .portfolio--wrapper .container ul li div span {
      background: rgba(249, 249, 249, 0.9); }
      @media screen and (max-width: 1080px) {
        body[data-theme="light"] .portfolio--wrapper .container ul li div span {
          background: rgba(249, 249, 249, 0.8); } }

/* Contact */
.contact--wrapper {
  background: linear-gradient(260deg, #1c1d25 65%, #101013 35%);
  -webkit-animation: content-blur 0.5s;
  animation: content-blur 0.5s; }
  @media screen and (max-width: 1080px) {
    .contact--wrapper {
      background: linear-gradient(200deg, #1c1d25 65%, #101013 35%); } }
  .contact--wrapper section {
    background: linear-gradient(160deg, transparent 93%, #f7df93 7%); }
    @media screen and (max-width: 1080px) {
      .contact--wrapper section {
        background: linear-gradient(60deg, transparent 93%, #a1fcdf 7%); } }
  .contact--wrapper #contact {
    margin-top: 20px; }
    @media screen and (max-width: 700px) {
      .contact--wrapper #contact {
        margin-top: -20px; } }
    .contact--wrapper #contact ul li {
      padding-top: 30px; }
      .contact--wrapper #contact ul li:nth-child(1), .contact--wrapper #contact ul li:nth-child(2) {
        width: 50%; }
        @media screen and (max-width: 580px) {
          .contact--wrapper #contact ul li:nth-child(1), .contact--wrapper #contact ul li:nth-child(2) {
            display: block;
            width: 100%; } }
      .contact--wrapper #contact ul li:nth-child(1) {
        padding-right: 20px; }
        @media screen and (max-width: 580px) {
          .contact--wrapper #contact ul li:nth-child(1) {
            padding-right: 0; } }
      .contact--wrapper #contact ul li:nth-child(2) {
        padding-left: 20px; }
        @media screen and (max-width: 580px) {
          .contact--wrapper #contact ul li:nth-child(2) {
            padding-left: 0; } }
      .contact--wrapper #contact ul li:nth-child(4) {
        text-align: right; }
      .contact--wrapper #contact ul li input, .contact--wrapper #contact ul li textarea {
        width: 100%;
        background: transparent;
        color: #f9f9f9;
        padding: 15px 5px;
        border-bottom: 2px solid #00b4b1; }
        .contact--wrapper #contact ul li input:hover, .contact--wrapper #contact ul li textarea:hover {
          border-bottom: 2px solid #a1fcdf; }
        .contact--wrapper #contact ul li input:focus, .contact--wrapper #contact ul li textarea:focus {
          border-bottom: 2px solid #f7df93; }
      .contact--wrapper #contact ul li textarea {
        height: 64px;
        resize: none; }
        .contact--wrapper #contact ul li textarea:focus {
          height: 200px; }

/* Light Theme */
body[data-theme="light"] .contact--wrapper {
  background: linear-gradient(260deg, #f9f9f9 65%, #eee 35%); }
  @media screen and (max-width: 1080px) {
    body[data-theme="light"] .contact--wrapper {
      background: linear-gradient(200deg, #f9f9f9 65%, #eee 35%); } }
  body[data-theme="light"] .contact--wrapper #contact ul li input, body[data-theme="light"] .contact--wrapper #contact ul li textarea {
    color: #101013; }

/* footer */
footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 22px; }
  @media screen and (max-width: 1080px) {
    footer {
      width: 80px;
      right: 5px;
      z-index: 999; } }
  @media screen and (max-width: 590px) {
    footer {
      display: none; } }
  footer p {
    padding: 0;
    color: rgba(161, 252, 223, 0.8);
    font-size: 11px;
    line-height: 16px;
    font-size: 1.1rem;
    line-height: 1.6rem; }
    footer p a {
      color: #1c1d25 !important;
      padding-bottom: 2px;
      border-bottom: 2px solid rgba(161, 252, 223, 0.7);
      font-size: 10px;
      line-height: 18px;
      font-size: 1rem;
      line-height: 1.8rem;
      font-weight: bold;
      display: inline-block; }
      footer p a:hover {
        border-bottom: 2px solid rgba(161, 252, 223, 0.5); }
      footer p a:active {
        border-bottom: 2px solid rgba(161, 252, 223, 0.3); }

/* JS Spinner */
.sk-three-bounce {
  margin: 40px 0;
  width: 80px; }
  .sk-three-bounce .sk-child {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #101013;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    margin-right: 4px; }
    .sk-three-bounce .sk-child.sk-bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s; }
    .sk-three-bounce .sk-child.sk-bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s; }

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* Light Theme */
body[data-theme="light"] .sk-three-bounce .sk-child {
  background: #eee; }

/* switch theme */
.switch {
  position: fixed;
  z-index: 9999;
  left: 30px;
  top: 90px; }
  @media screen and (max-width: 1080px) {
    .switch {
      top: inherit;
      left: 100px;
      bottom: 32px; } }
  @media screen and (max-width: 480px) {
    .switch {
      left: 80px;
      bottom: 22px; } }
  @media screen and (max-width: 370px) {
    .switch {
      left: 75px; } }
  @media screen and (max-width: 359px) {
    .switch {
      left: 70px; } }
  @media screen and (max-width: 330px) {
    .switch {
      left: 62px; } }
  .switch .switch__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background: #f9f9f9;
    top: 10px;
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    box-shadow: 0 0 2px 2px rgba(16, 16, 19, 0.4);
    cursor: pointer;
    outline: none;
    transition: all 0.6s ease-in-out 1.6s; }
    @media screen and (max-width: 480px) {
      .switch .switch__input {
        height: 16px;
        width: 16px; } }
    .switch .switch__input:checked::before {
      display: none; }
    .switch .switch__input:checked {
      background: #1c1d25;
      box-shadow: 0 0 2px 2px rgba(249, 249, 249, 0.5); }

/*****************************************************************/

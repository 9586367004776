/* switch theme */
.switch {
    position: fixed;
    z-index: 9999;
    left: 30px;
    top: 90px;

    @media screen and (max-width: 1080px) {
        top: inherit;
        left: 100px;
        bottom: 32px;
    }

    @media screen and (max-width: 480px) {
        left: 80px;
        bottom: 22px;
    }

    @media screen and (max-width: 370px) {
        left: 75px;
    }

    @media screen and (max-width: 359px) {
        left: 70px;
    }

    @media screen and (max-width: 330px) {
        left: 62px;
    }

    .switch__input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        background: $light;
        top: 10px;
        height: 20px;
        width: 20px;
        border: 0;
        @include rounded--corners(100%);
        @include box--shadow(0 0 2px 2px rgba($darker, .4));
        cursor: pointer;
        outline: none;
        @include transitions(all .6s ease-in-out 1.6s);

        @media screen and (max-width: 480px) {
            height: 16px;
            width: 16px;
        }

        &:checked::before {
            display: none;
        }
        
        &:hover {
            @extend .hov;
        }
        
        &:checked {
            background: $dark;
            @include box--shadow(0 0 2px 2px rgba($light, .5));
        }
    }
}
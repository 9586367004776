/* sass variables */
$base-dark: #00b4b1;
$base-light: #a1fcdf;
$base-mid: #f7df93;
$light: #f9f9f9;
$darker-light: #eee;
$dark: #1c1d25;
$darker: #101013;
$full: 100%;
$fixH: 80px;
$fixM: 60px;
$center: 0 auto;

/* sass mixing */

@mixin transitions($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}

@mixin rounded--corners($size:50%) {
    border-radius: $size;
    -webkit-border-radius: $size;
    -moz-border-radius: $size;
}

@mixin box--shadow($size) {
    -webkit-box-shadow: $size;
    -moz-box-shadow: $size;
    box-shadow: $size;
}

/* extendions */
.floating { 
    display: inline-block;
    vertical-align: top;
}

.hov { 
    opacity:.80;
    -moz-opacity:.80; 
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80); 
    -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    @include transitions(all .4s ease);
}

.act { 
    opacity: .60; 
    -moz-opacity: .60; 
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60); 
    -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    @include transitions(all .4s ease);
}

.fade {
    @include transitions(all .4s ease);
}

.no--fade, 
.no--fade * { 
    @include transitions(none); 
}

.fade--logos {
    @include transitions(all 1s ease-in-out 0s);
}

.opacity--reset { 
    opacity: 1.0;
    -moz-opacity: 1.0; 
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100); 
    -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.opacity--grad {
    opacity: .2;
    -moz-opacity: .2; 
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20); 
    -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.scal {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.scal--no {
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
}

.centering {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.centering--left {
    left: 0;
    margin-right: 0;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.img {
    width: $full;
    height: auto;
    line-height: 0;
}

/* fonts */
@mixin font-size($size:1.6, $line: $size * 1.5){
    font-size: ($size * 10) + px;
    line-height: ($line * 10) + px;
    font-size: $size + rem;
    line-height: $line + rem;
}

.gradient {
    background: -webkit-linear-gradient(to right, $base-dark 30%, $base-mid 80%);
    background: -o-linear-gradient(to right, $base-dark 30%, $base-mid 80%);
    background: -moz-linear-gradient(to right, $base-dark 30%, $base-mid 80%);
    background: linear-gradient(to right, $base-dark 30%, $base-mid 80%);
}

.gradient--title {
    background: -webkit-linear-gradient(to right, $base-dark 30%, $base-light 80%);
    background: -o-linear-gradient(to right, $base-dark 30%, $base-light 80%);
    background: -moz-linear-gradient(to right, $base-dark 30%, $base-light 80%);
    background: linear-gradient(to right, $base-dark 30%, $base-light 80%);
}

.text--gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* animations */
@mixin animations($animation...) {
    -moz-animation: $animation;
    -o-animation: $animation;
    -webkit-animation: $animation;
    -ms-animation: $animation;
    animation: $animation;
}

@keyframes content-blur {
    0% { -webkit-filter: blur(3px); }
    100% { -webkit-filter: blur(0px); }
}
@-moz-keyframes content-blur {
    0% { -webkit-filter: blur(3px); }
    100% { -webkit-filter: blur(0px); }
}
@-webkit-keyframes content-blur {
    0% { -webkit-filter: blur(3px); }
    100% { -webkit-filter: blur(0px); }
}
/* JS Spinner */
.sk-three-bounce {
    margin: 40px 0;
    width: 80px;

    .sk-child {
        width: 15px;
        height: 15px;
        display: inline-block;
        background: $darker;
        @include rounded--corners(100%);
        @include animations(sk-three-bounce 1.4s ease-in-out 0s infinite both);
        margin-right: 4px;

        &.sk-bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s; 
        }
        
        &.sk-bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s; 
        }
    }

    @-webkit-keyframes sk-three-bounce {
        0%, 
        80%, 
        100% {
            -webkit-transform: scale(0);
            transform: scale(0); 
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1); 
        } 
    }

    @keyframes sk-three-bounce {
        0%, 
        80%, 
        100% {
          -webkit-transform: scale(0);
            transform: scale(0); 
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1); 
        } 
    }
}

/* Light Theme */
body[data-theme="light"] {

    .sk-three-bounce .sk-child {
        background: $darker-light;
    }
}
body, 
div, 
span, 
section, 
article, 
header, 
footer, 
nav, 
aside, 
hgroup, 
h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
p, 
a, 
em, 
font, 
img, 
strong, 
b, 
u, 
i, 
ol, 
ul, 
li, 
fieldset, 
form, 
label, 
select, 
option, 
input, 
textarea, 
table, 
tr, 
td, 
th, 
blockquote { 
	margin:0; padding:0; border:0; outline:0; }

a:click { 
    outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }
 
ul, 
ol, 
li { 
    list-style:none; 
}

.right-text {
  text-align: right;
}
.right {
  float: right;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

input, form, textarea {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit; }

html,
body {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }
  
body {
  margin: 0;
  line-height: normal; }

/* @media screen and (max-width: 1279px) {
    body, html { 
        overflow: auto; 
        overflow-y: auto !important; 
        overflow-x: hidden !important;
    }
} */
  
a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }
q:before,
q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

a, 
p a, 
a img { 
    transition: all .2s ease; 
    -webkit-transition: all .2s ease; 
    -moz-transition: all .2s ease; 
    -ms-transition: all .2s ease; 
    -o-transition: all .2s ease; }
 
img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"], input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }
  
button[disabled],
html input[disabled] {
  cursor: default; }

button {
    background: transparent;
    border: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }
  
input[type="search"] {
  -webkit-appearance: textfield;
   box-sizing: content-box;
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box; }
   
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }
  
table {
  border-collapse: collapse;
  border-spacing: 0; }
  
input {
  /* -webkit-appearance: none; */
  border-radius: 0;
  line-height: 2em;
  transition: opacity .4s ease-out;
  outline: none; }

.error input {
  border-color: #FF4C4C; }

.error input[type="submit"] {
  background: #FF4C4C;
  color: #fff; }

.loading input {
  opacity: .5; }

a, p a, a img { 
    transition: all .1s ease; 
    -webkit-transition: all .2s ease; 
    -moz-transition: all .2s ease; 
    -ms-transition: all .2s ease; 
    -o-transition: all .2s ease;
}
.no-fade, .no-fade * { 
    transition: none; 
    -webkit-transition: none; 
    -moz-transition: none; 
    -ms-transition: none; 
    -o-transition: none;  
}
.ellipsis {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
}
.centering { 
    display:block; 
    margin:0 auto;
    float:none; 
}
.clear { 
    display: block;
    clear: both; 
}
.button, input[type="submit"] {
    cursor: pointer;
}
.button.desabled {
    cursor: default;
}
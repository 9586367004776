/* Portfolio */
.portfolio--wrapper {
    background: -webkit-linear-gradient(-197deg, $dark 65%, $darker 35%);
    background: -o-linear-gradient(-197deg, $dark 65%, $darker 35%);
    background: -moz-linear-gradient(-197deg, $dark 65%, $darker 35%);
    background: linear-gradient(-197deg, $dark 65%, $darker 35%);

    @include animations(content-blur .5s);

    section {
        background: -webkit-linear-gradient(20deg, transparent 92%, $base-dark 8%);
        background: -o-linear-gradient(20deg, transparent 92%, $base-dark 8%);
        background: -moz-linear-gradient(20deg, transparent 92%, $base-dark 8%);
        background: linear-gradient(20deg, transparent 92%, $base-dark 8%);

        @media screen and (max-width: 1080px) {
            background: -webkit-linear-gradient(70deg, transparent 92%, $base-mid 8%);
            background: -o-linear-gradient(70deg, transparent 92%, $base-mid 8%);
            background: -moz-linear-gradient(70deg, transparent 92%, $base-mid 8%);
            background: linear-gradient(70deg, transparent 92%, $base-mid 8%);
        }
    }

    .container {

        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-areas: 
                "portfolio-1 portfolio-1 portfolio-2" 
                "portfolio-1 portfolio-1 portfolio-3" 
                "portfolio-4 portfolio-5 portfolio-6" 
                "portfolio-7 portfolio-8 portfolio-9" 
                "portfolio-10 portfolio-11 portfolio-11" 
                "portfolio-12 portfolio-11 portfolio-11" 
                "portfolio-13 portfolio-14 portfolio-15"
            ;
            grid-gap: 10px 10px;

            @media screen and (max-width: 1440px) {
                display: block;
            }

            li {
                line-height: 0;

                @media screen and (max-width: 1440px) {
                    width: $full / 2;
                    padding: 0 10px 10px 0;
                    display: inline-block;
                }

                @media screen and (max-width: 900px) {
                    width: $full;
                    display: block;
                    padding-right: 0;
                    padding-bottom: 30px;
                }

                &:nth-child(1) { 
                    grid-area: portfolio-1; 
                }

                &:nth-child(2) { 
                    grid-area: portfolio-2; 
                }

                &:nth-child(3) { 
                    grid-area: portfolio-3; 
                }

                &:nth-child(4) { 
                    grid-area: portfolio-4; 
                }

                &:nth-child(5) { 
                    grid-area: portfolio-5; 
                }

                &:nth-child(6) { 
                    grid-area: portfolio-6;
                }

                &:nth-child(7) { 
                    grid-area: portfolio-7; 
                }

                &:nth-child(8) { 
                    grid-area: portfolio-8; 
                }

                &:nth-child(9) { 
                    grid-area: portfolio-9; 
                }

                &:nth-child(10) { 
                    grid-area: portfolio-10; 
                }

                &:nth-child(11) { 
                    grid-area: portfolio-11; 
                }

                &:nth-child(12) { 
                    grid-area: portfolio-12; 
                }

                &:nth-child(13) { 
                    grid-area: portfolio-13; 
                }

                &:nth-child(14) { 
                    grid-area: portfolio-14; 
                }

                &:nth-child(15) { 
                    grid-area: portfolio-15;
                }

                article {
                    overflow: hidden;
                    position: relative;
                    height: $full;

                    span {
                        width: $full;
                        height: $full;
                    }
                }

                img {
                    position: relative;
                    z-index: 44;
                    color: $dark;
                    width: $full;
                    height: $full;
                }

                div {
                    width: $full;
                    height: $full;
                    position: absolute;
                    z-index: 55;
                    background: rgba($dark, .15);

                    @media screen and (min-width: 1081px) {
                        -webkit-transform: perspective(1px) translateZ(0);
                        transform: perspective(1px) translateZ(0);
                        -webkit-transition-property: color;
                        transition-property: color;
                        -webkit-transition-duration: .2s;
                        transition-duration: .2s;
                    }

                    span {
                        position: absolute;
                        z-index: -1;
                        background: rgba($dark, .9);
                        padding: 25px 30px;
                        width: auto;
                        height: auto;

                        @media screen and (min-width: 1081px) {
                            top: -1px;
                            left: -1px;
                            right: -1px;
                            bottom: -1px;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: 0 50%;
                            transform-origin: 0 50%;
                            -webkit-transition-property: transform;
                            transition-property: transform;
                            -webkit-transition-duration: .3s;
                            transition-duration: .3s;
                            -webkit-transition-timing-function: ease-out;
                            transition-timing-function: ease-out;
                        }

                        @media screen and (max-width: 1080px) {
                            background: rgba($dark, .75);
                            width: $full;
                            height: $full;
                        }

                        @include font-size(1.5, 3.4);

                        @media screen and (max-width: 480px) {
                            padding: 15px 20px;
                        }

                        h2 {
                            color: $base-dark;
                            @include font-size(2.4, 3.4);

                            @media screen and (max-width: 480px) {
                                @include font-size(2.2, 2.8);
                            }
                        }

                        p, h3 {
                            padding: 0;
                        }

                        h3 {
                            @include font-size(1.4, 3.4);

                            @media screen and (max-width: 480px) {
                                @include font-size(1.2, 2.2);
                                padding-top: 5px;
                            }
                        }

                        i {
                            padding: 0 5px;
                        }

                        p {
                            position: absolute;
                            bottom: 17px;

                            @media screen and (max-width: 480px) {
                                bottom: 12px;
                                @include font-size(1.3, 3);
                            }

                            @media screen and (max-width: 370px) {
                                @include font-size(1.2, 3);
                            }
                        }
                    }

                    &:hover span,
                    &:focus span,
                    &:active span {

                        @media screen and (min-width: 1081px) {
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}

/* Light Theme */
body[data-theme="light"] {

    .portfolio--wrapper {
        background: -webkit-linear-gradient(-197deg, $light 65%, $darker-light 35%);
        background: -o-linear-gradient(-197deg, $light 65%, $darker-light 35%);
        background: -moz-linear-gradient(-197deg, $light 65%, $darker-light 35%);
        background: linear-gradient(-197deg, $light 65%, $darker-light 35%);

        .container ul li div {
            background: rgba($light, .2);

            span {
                background: rgba($light, .9);

                @media screen and (max-width: 1080px) {
                    background: rgba($light, .8);
                }
            }
        }
    }
}